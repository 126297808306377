import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});